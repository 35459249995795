import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Category, categorySelected } from '../services/category/get-category/types';
import { FetchError, getCategories } from '../services';
import { COOKIE_CATEGORY_ID } from '../components/constants';
import { getCookie, setCookie, generateCategoryTree } from '../utils';

interface CategoryContextProps {
    categories: Category[];
    filteredCategories: Category[];
    filteredSubCategories: categorySelected[];
    mainCategoryId: number | null;
    handleCategorySelection: (categoryId: number) => void;
    handleSubCategorySelection: (categoryId: number, subCategory: number) => categorySelected[];
    error: string | null;
    isCategoryLoading: boolean;
    categoryTree: Category[] | undefined;
}

const CategoryContext = createContext<CategoryContextProps | undefined>(undefined);

export const useCategory = (): CategoryContextProps => {
    const context = useContext(CategoryContext);
    if (!context) {
        throw new Error('useCategory must be used within a CategoryProvider');
    }
    return context;
};

export const CategoryProvider: React.FC = ({ children }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
    const [filteredSubCategories, setFilteredSubCategories] = useState<categorySelected[]>([]);
    const [mainCategoryId, setMainCategoryId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isCategoryLoading, setIsLoading] = useState<boolean>(true);
    const [categoryTree, setCategoryTree] = useState<Category[]>();

    const fetchCategories = useCallback(async () => {
        try {
            setIsLoading(true);
            const fetchedCategories = await getCategories();
            if (!Array.isArray(fetchedCategories)) {
                throw new Error('Failed to fetch categories.');
            }

            setCategories(fetchedCategories);

            const parentCategory = getCookie(COOKIE_CATEGORY_ID);
            if (!parentCategory) {
                throw new Error('No category ID found in cookies');
            }

            const mainCategoryId = Number(parentCategory);
            setMainCategoryId(mainCategoryId);

            const filteredChildCategories = fetchedCategories.filter(
                (category: { parentCategory: number }) => category.parentCategory === mainCategoryId
            );
            if (!filteredChildCategories.length) {
                throw new FetchError(`Parent category ID ${mainCategoryId} not a parentCategory in fetched categories`);
            }

            setFilteredCategories(filteredChildCategories);

            const categoryTree = generateCategoryTree(fetchedCategories, mainCategoryId);
            setCategoryTree(categoryTree);

            setError(null);
        } catch (error) {
            console.error('CategoryContext: Failed to fetch categories:', error);
            if (error instanceof FetchError) {
                if (error.status === 400) {
                    setError('Bad Request: The server could not understand the request to fetch categories');
                } else if (error.status === 404) {
                    setError('Not Found: The requested categories could not be found.');
                } else {
                    setError(error.message || 'Unknown error');
                }
            } else if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Unknown error');
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        void fetchCategories();
    }, [fetchCategories]);

    const handleCategorySelection = (categoryId: number) => {
        const filtered = categories.filter(category => category.parentCategory === categoryId);
        setFilteredCategories(filtered);
        setMainCategoryId(categoryId);
    };

    const handleSubCategorySelection = (categoryId: number, subCategory: number) => {
        let defaultSubCategoryId: number;
        const preSelectedSubCategory = getCookie('subCategory');
        const filtered = categories.filter(category => category.parentCategory === categoryId);
        if (subCategory === 0 && preSelectedSubCategory && filtered.length) {
            defaultSubCategoryId =
                filtered.length && filtered.find(category => category.categoryId === parseInt(preSelectedSubCategory, 10))
                    ? parseInt(preSelectedSubCategory, 10)
                    : filtered[0].categoryId;
            setCookie('subCategory', '');
        } else {
            defaultSubCategoryId = filtered.length && subCategory === 0 ? filtered[0].categoryId : subCategory;
            if (subCategory !== 0) {
                setCookie('subCategory', subCategory.toString());
            }
        }
        const subCategoryList = filtered.map(category => {
            return {
                categoryId: category.categoryId,
                selected: category.categoryId === defaultSubCategoryId,
                name: category.name
            };
        });
        setFilteredSubCategories(subCategoryList);
        return subCategoryList;
    };

    return (
        <CategoryContext.Provider
            value={{
                categories,
                filteredCategories,
                filteredSubCategories,
                mainCategoryId,
                handleCategorySelection,
                handleSubCategorySelection,
                error,
                isCategoryLoading,
                categoryTree
            }}
        >
            {children}
        </CategoryContext.Provider>
    );
};
